<template lang="html">

  <section class="site-map">
    <bannerHead dataTitle="Mapa Web"></bannerHead>
    <breacrumbs dataTitle="Mapa Web"></breacrumbs>
    <div class="list-map">
      <ul class="container">
        <li>
            <router-link :class="[$route.name == 'team-member' ? 'router-link-active' : '']" :to="{name:'conversationDevelopment'}">
              Conversaciones de Desarrollo
            </router-link>
          </li>
          <li>
            <router-link :class="[$route.name == 'team-member' ? 'router-link-active' : '']" :to="{name:'offersPage'}"> Vacantes</router-link>
          </li>
      </ul>
    </div>
  </section>

</template>

<script lang="js">
  import { contentStore } from '@/stores/contents'
  import bannerHead from '@/components/banner-head.vue'
  import breacrumbs from '@/components/breacrumbs.vue'
  export default {
    name: 'site-map',
    props: [],
    components: {
      bannerHead,
      breacrumbs
    },
    setup() {
      const store = contentStore();

      return {
        store
      }
    },

  }


</script>

<style scoped lang="scss">
  .site-map {}
</style>